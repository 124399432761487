import { Fragment } from "react";
import { Link } from "react-router-dom";
import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaPinterest } from 'react-icons/fa';
const Footer = () => {
  return (
    <Fragment>
      <div className="bg-zinc-800 px-2 sm:px-4 py-4 border-t border-t-red-500">
        <div className=" text-gray-500 mx-auto md:px-28 container ">
          <div className="md:flex md:justify-between">
            <div className="flex justify-center">
              <Link to="/" className="flex">
                <span className="text-gray-200 hover:text-gray-400 text-xl">
                  Suresh-Gosavi
                </span>
              </Link>
            </div>

            <div className="flex flex-row mt-8 md:mt-0 justify-center">
  <a href="https://www.facebook.com/suresh.gosavi.10" rel="noreferrer" target="_blank" className="text-2xl mx-2 text-blue-500"><FaFacebook /></a>
  <a href="https://www.instagram.com/sureshgosavi.24"rel="noreferrer" target="_blank" className="text-2xl mx-2 text-pink-400  "><FaInstagram /></a>
  <a href="https://www.pinterest.com/pin/431993789230690238/" rel="noreferrer" target="_blank" className="text-2xl mx-2 text-red-400  "><FaPinterest /></a>
</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
